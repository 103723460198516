<template>
	<div class="fp-section fp-auto-height headvisual-fullwidht">
		<swiper ref="headvisualSlider" :options="swiperOptions">
			<swiper-slide v-for="(itemSlide, key) in itemSlides" :key="key">
				<div class="headvisual headvisual--has-image hero-image">
					<div class="headvisual--image-wrapper">
						<div class="ce-image">
							<media-gallery
								:gallery="itemSlide.content.gallery"
							/>
						</div>
					</div>
					<div class="headvisual--content-wrapper has--circle">
						<div class="page--wrapper">
							<div
								class="headvisual--content ps-lg-12"
								data-aos="fade-right"
								data-aos-duration="1000"
								data-aos-delay="1000"
							>
								<h1 class="headvisual--title">
									{{ itemSlide.content.header }}
								</h1>
								<HeadvisualNavigation
									v-if="itemSlides.length > 1"
								/>
								<div class="mb-6 headvisual--content-subline">
									<html-parser
										:content="itemSlide.content.bodytext"
									/>
								</div>
							</div>
						</div>
						<PolygonShape
							class="headvisual-decor"
							data-aos="fade-right"
							data-aos-duration="900"
							data-aos-delay="900"
						/>
					</div>
				</div>
			</swiper-slide>
			<HeadvisualScrollIndicator />
		</swiper>
	</div>
</template>

<script>
import objectFitImages from 'object-fit-images'
import HeadvisualScrollIndicator from '~/components/molecules/navigation/HeadvisualScrollIndicator'
import HeadvisualNavigation from '~/components/molecules/navigation/HeadvisualNavigation'
import PolygonShape from '~/components/organisms/hero/PolygonShape'

import { mapState } from 'vuex'

import { Swiper, SwiperSlide, directive, Pagination } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { faH1 } from '@fortawesome/pro-light-svg-icons'
export default {
	name: 'Headvisual',
	props: {
		itemSlides: {
			type: Array,
		},
	},
	components: {
    Swiper,
    SwiperSlide,
    Pagination,
    HeadvisualScrollIndicator,
    HeadvisualNavigation,
    PolygonShape,
    faH1
},
	directives: {
		swiper: directive,
	},
	computed: {
		swiper() {
			return this.$refs.headvisualSlider.$swiper
		},
	},
	data() {
		return {
			swiperOptions: {
				scrollbar: '.swiper-scrollbar',
				mousewheelControl: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 15000,
				},
				simulateTouch: false,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},

				breakpoints: {
					992: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					640: {
						slidesPerView: 1,
					},
					0: {
						slidesPerView: 1,
					},
				},
			},
		}
	},
	computed: {
		...mapState({
			HeroImage: (state) => {
				return state.typo3.page.page.appearance.backendLayout
			},
		}),
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$headvisual--min-height: 200px !default;
$headvisual--min-height--large-breakpoint: 500px !default;
$headvisual--content-wrapper--background: $primary !default;
$headvisual--content-wrapper--color: color(primary, dark) !default;
$headvisual--bottom-wrapper--background: color(white) !default;

.headvisual-container {
	position: relative;
}

.headvisual {
	video,
	img {
		display: block;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		object-position: 70% 50%;
		border: 0;
		position: absolute;
		border-radius: 0;

		@include media-breakpoint-up(md) {
			position: relative;
			object-position: inherit;
		}
	}

	.btn {
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
}
.headvisual--has-image {
	@include media-breakpoint-up(lg) {
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 50%;
			opacity: 1;
			z-index: 2;
			background: linear-gradient(
				6deg,
				rgba(255, 254, 254, 0),
				transparent 60%,
				#fff
			);
		}
	}
}
.headvisual figcaption {
	display: none;
	position: absolute;
	bottom: 5%;
	right: 3%;
	text-shadow: 0px 0px 10px #002852;
	color: color(white);
	font-size: rem(20);
}
.hero-image .ce-image {
	height: 100vh;
}
.headvisual--title {
	font-weight: 800;
	@include responsive-type(5, 1);
}
.headvisual .has--circle {
	overflow: hidden;
	position: relative;
	color: #2b2d2e;

	p {
		font-size: rem(20);
		line-height: 32px;
	}
}
.headvisual--content-subline {
	display: none;
}
// Overlay both containers, but both containers could be larger than the other
// and thus increase the total height of Headvisual.
.headvisual {
	width: 200%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	overflow: hidden;
}
.headvisual--image-wrapper,
.headvisual--content-wrapper,
.headvisual--bottom-wrapper {
	flex: 1 1 auto;
	width: 50%;
	min-height: 1px;
}
.headvisual--image-wrapper {
	figure,
	picture,
	iframe {
		display: block;
		height: 100vh;
	}
	.responsive-video {
		height: 100vh;
		padding-top: 0;
	}

	.ce-gallery__col {
		@include media-breakpoint-down(md) {
			margin-top: 0 !important;
		}
	}
}
.headvisual--content-wrapper {
	display: flex;
	align-items: center;
	position: relative;
	top: 0;
	left: -50%;
}
.headvisual--bottom-wrapper {
	flex-grow: 0;
}
.headvisual--content-subline {
	font-size: rem(18);
	margin-bottom: 20px;
	display: block;
}
@include media-breakpoint-up(md) {
	.headvisual figcaption {
		display: block;
	}
}
.headvisual .page--wrapper {
	width: 100%;
	padding: 25px;
	@include media-breakpoint-up(md) {
		padding: 0;
	}
}
.headvisual--content-inner {
	height: 100%;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}
	@include media-breakpoint-only(sm) {
		&:before {
			width: 66.666%;
		}
	}
}
.headvisual--content {
	flex: 0 1 auto;
	position: relative;
	// padding-top: 80%;
	padding-bottom: 20%;
	width: 100%;
	display: flex;
	flex-direction: column;
	transition: all 0.5s;
	@include media-breakpoint-up(xl) {
		display: block;
		padding: 30px;
		max-width: 32.666%;
	}
	@include media-breakpoint-only(md) {
		display: block;
		padding: 30px;
		max-width: 65.666%;
	}
	@include media-breakpoint-only(lg) {
		display: block;
		padding: 30px;
		max-width: 40.666%;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
	}

	@media only screen and (max-height: 800px) {
		max-width: 65.666%;
	}

	@media only screen and (max-height: 900px) {
		max-width: 65.666%;
	}
}
.headvisual--bottom-wrapper {
	background: $headvisual--bottom-wrapper--background;
	padding: 20px 0;
	@include media-breakpoint-only(sm) {
		padding: 30px 0;
	}
}
.hero-image {
	max-height: 100vh;
	min-height: $headvisual--min-height;
	@include media-breakpoint-only(sm) {
		min-height: $headvisual--min-height--large-breakpoint;
	}
	.headvisual--content-wrapper {
		height: 100vh;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		text-align: left;
		& > .headvisual--content-inner {
			flex: 1 1 auto;
			width: 100%;
		}
	}
	.headvisual--content {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.platform-ios {
	.hero-image {
		max-height: calc(100vh - 70px);
		.headvisual--content-wrapper {
			height: calc(100vh - 70px);
		}
	}
	@include media-breakpoint-only(sm) {
		.hero-image {
			max-height: calc(100vh - 50px);
			.headvisual--content-wrapper {
				height: calc(100vh - 70px);
			}
		}
	}
	&.platform-ios_10 {
		.headvisual--image-wrapper,
		figure.responsive,
		figure.responsive picture {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
		}
		.headvisual {
			video,
			img {
				flex: 1 1 auto;
			}

			.headvisual {
				video,
				img {
					flex: 1 1 auto;
				}
			}
		}
	}
}
.platform-android {
	@include media-breakpoint-only(lg) {
		.hero-image {
			max-height: calc(100vh - 50px);
			.headvisual--content-wrapper {
				height: calc(100vh - 50px);
			}
		}
	}
}
.headvisual-fullwidht {
	left: 50%;
	margin-left: -50vw;
	width: 100vw;
	.fp-tableCell {
		width: 100vw;
	}
}
</style>
