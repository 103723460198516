<template>
	<div class="headvisual-static headvisual--has-image">
		<swiper
			ref="headvisualStaticSlider"
			:options="swiperOptions"
		>
			<swiper-slide
				v-for="(col, key) in isHeadvisualStaticImg"
				:key="key"

			>
				<ce-media-headvisual
					:file="col"
				/>
			</swiper-slide>


		</swiper>

		<div class="swiper-pagination-round" slot="pagination"></div>
		<div class="swiper-button-prev" v-if="showNavigationButtons" slot="button-prev"></div>
		<div class="swiper-button-next" v-if="showNavigationButtons" slot="button-next"></div>
	</div>
</template>

<script>
import objectFitImages from 'object-fit-images';
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import CeMediaHeadvisual from '~/components/atoms/media/FileHeadvisual';
export default {
	name: 'HeadvisualStatic',
	components: {
		Swiper,
		SwiperSlide,
		CeMediaHeadvisual
	},
	computed: {
		swiper() {
			return this.$refs.headvisualStaticSlider.$swiper
		},
		...mapState({
			isHeadvisualStaticImg: (state) => {
				return state.typo3.page.page.resources.media;
			},
		}),
		showNavigationButtons() {
        	return this.isHeadvisualStaticImg.length > 1;
    	}
	},
	data() {
		return {
			swiperOptions: {
				mousewheelControl: true,
				watchOverflow: true,

				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				pagination: {
					clickable: true,
					el: '.swiper-pagination-round',
				},
				simulateTouch: false,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				breakpoints: {
					992: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					640: {
						slidesPerView: 1,

					},
					0: {
						slidesPerView: 1,
					}
				},
			}
		}
	},
}
</script>
<style lang="scss">
	@import '~@/assets/scss/common';
	$headvisual--min-height: 200px !default;
	$headvisual--margin-top: 80px !default;

	.headvisual-static {
		video,
		img {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			border: 0;
			position: relative;
			border-radius: 0;
		}
		&.headvisual--has-image {
			@include media-breakpoint-up(lg) {
				&:before {
					height: 40%;
				}
			}
		}
	}

	.headvisual-static {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		overflow: hidden;

		.ce-media-image {
			height: 100%;
			figure {
				height: 100%;
			}
			> div {
				height: 100%;
			}
		}
	}

	.headvisual-static {
		.swiper-wrapper {
			height: 100%;

			@include media-breakpoint-up(lg) {
				max-height: 680px;
			}

			.cWExZE {
				position: fixed !important;
				padding: 0 !important;
					&:before {
						display: none;
					}
			}
			.ly-button-wrapper {
				width: 90px;
				height: 63px;
				border-radius: 5em;
			}
			.cWExZE .ly-text {
				display: none;
			}
		}
		.swiper-container {
			width: 100%;
			margin: 0;
		}
		@include media-breakpoint-up(lg) {
			.swiper-button-prev {
				left: 40px;
			}
			.swiper-button-next {
				right: 40px;
			}
		}
		.swiper-pagination-bullet:only-child {
			visibility: hidden;
		}
	}
	.headvisual-static {
		.swiper-pagination-round {
			display: flex;
			align-items: center;
			margin: 0 auto;
			margin-top: -30px;
			z-index: 2;
		}
		.swiper-pagination-bullet {
			margin: 5px;
		}
	}

	.is--headvisual-static-img + .page--wrapper {
		margin: 0;
	}
</style>
