<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="376"
		height="382"
		viewBox="0 0 376 382"
	>
		<g fill="none" fillRule="evenodd" opacity="0.799">
			<path
				class="decore-path"
				fill="#a9d5f0"
				fillRule="nonzero"
				d="M61.302 332.93l108.032 42.604c9.115 3.614 21.833 6.466 36.263 6.466 23.922 0 52.022-8.18 74.238-35.185l73.856-89.774c16.71-20.16 36.836-67.138 7.025-114.116L298.06 45.166c-14.05-22.065-52.21-55.92-106.131-41.845L79.718 32.23C54.277 38.888 10.607 64.756 7.19 120.484L.165 236.5c-1.71 26.247 9.302 75.889 61.137 96.428zM246.798 77.878l-.19-.38.19.38zM60.732 239.924l7.025-115.638c1.519-24.154 22.782-31.762 27.339-33.096l112.02-29.098c23.355-6.087 37.214 11.981 39.874 15.975l62.273 97.762c12.91 20.352.192 39.178-2.847 42.984l-73.668 89.391c-15.378 18.639-37.023 12.173-41.58 10.651L83.513 276.251c-22.403-8.747-22.974-31.57-22.782-36.327z"
			></path>
			<path
				class="decore-border"
				fill="#FFF"
				stroke="#a9d5f0"
				d="M60.732 239.924l7.025-115.638c1.519-24.154 22.782-31.762 27.339-33.096l112.02-29.098c23.355-6.087 37.214 11.981 39.874 15.975l62.273 97.762c12.91 20.352.192 39.178-2.847 42.984l-73.668 89.391c-15.378 18.639-37.023 12.173-41.58 10.651L83.513 276.251c-22.403-8.747-22.974-31.57-22.782-36.327z"
			></path>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PolygonShape',
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.headvisual {
	.page--wrapper {
		z-index: 5;
	}

	.headvisual-decor {
		position: absolute;
		width: 100%;
		height: 100%;
		max-width: 150%;
		margin-left: -35%;

		&.aos-animate {
			opacity: 0.9;
		}

		@include media-breakpoint-down(lg) {
			width: 140%;
			max-width: 160%;
			height: 140%;
			margin-left: -55%;
		}
		@include media-breakpoint-up(lg) {
			margin-left: -45%;
			width: 120%;
			height: 120%;
		}
		@include media-breakpoint-down(md) {
			width: 180%;
			max-width: 160%;
			height: 140%;
			margin-left: -55%;
		}
		@include media-breakpoint-down(sm) {
			width: 210% !important;
			max-width: 280% !important;
			height: 330% !important;
			margin-left: -65% !important;
			margin-top: 20% !important;
		}
		@media only screen and (max-height: 900px) {
			margin-left: -2rem;
			width: 80%;
			height: 200%;
		}
		@media only screen and (max-height: 800px) {
			margin-left: -2rem;
			width: 80%;
			height: 200%;
		}
	}

	@include media-breakpoint-down(md) {
		.decore-border {
			stroke: #fff;
		}
		.decore-path {
			fill: #fff;
		}
	}
}
</style>
