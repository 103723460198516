<template>
	<div class="fp-section fp-auto-height headvisual-fullwidht">
		<div class="headvisual headvisual--has-image hero-image--video">
			<div class="headvisual--image-wrapper">
				<div class="ce-image">
					<div
						v-for="(col, key) in isHeadvisualStaticVideo"
						:key="key"
					>
						<ce-media-file
							:file="col"
						/>
					</div>
				</div>
			</div>
		</div>
		<HeadvisualScrollIndicator />
	</div>
</template>

<script>
import HeadvisualScrollIndicator from '~/components/molecules/navigation/HeadvisualScrollIndicator';
import { mapState } from 'vuex';
export default {
	name: 'Herovideo',
	components: {
		HeadvisualScrollIndicator,
	},
	computed: {
		...mapState({
			isHeadvisualStaticVideo: (state) => {
				return state.typo3.page.page.resources.media;
			}
		}),
	},
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	.hero-image--video {
		&.headvisual {
			width: 100%;
			margin-bottom: -15px;
		}
		.cWExZE:before {
			display: none;
		}
		.ly-button-wrapper {
			width: 90px;
			height: 63px;
			border-radius: 5em;
		}
		.cWExZE .ly-text {
			display: none;
		}
		&.headvisual--has-image {
			@include media-breakpoint-up(lg) {
				&:before {
					height: 40%;
				}
			}
		}
	}
</style>
